app.utils.str = {
	capitalize: function(str) {
		if (!str) {
			return '';
		}
		return str.charAt(0).toUpperCase() + str.slice(1);
	},
	stripTags: function(str) {
		return String(str).replace(/(<([^>]+)>)/gi, "");
	},
	unescape: function(str) {
		var txt = document.createElement("TEXTAREA");
		var res = $(txt).html(str).text();
		$(txt).remove();
		return res;
	},
	escape: function(str) {
		if (!str) {
			return '';
		}
		return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
	},
	clear: function(str, escapeToSpecialSymbols) {
		if (!str) {
			return '';
		}
		if (escapeToSpecialSymbols) {
			str = str.replace(/"/g, '&quot;');
		}
		return $.trim(str.replace(/<[^<]*>/g, ' ').replace(/["]+/g, ' ').replace(/ +/g, ' '))
	},
	getProdId: function(str) {
		if (!str) {
			return null;
		}
		var tmp = String(str).match(/-([\d]+)\.html$/);
		if (tmp && tmp[1]) {
			return Number(tmp[1]);
		}
		return null;
	},
	formatBytes: function(bytes, decimals) {
		if (!decimals) {
			decimals = 2;
		}
		if (bytes === 0) {
			return '0 Bytes'
		}
		var k = 1024;
		var dm = decimals < 0 ? 0 : decimals;
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		var i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	},
	ltrim: function(str, chars, anySymbols) {
		if (!str) {
			return str;
		}
		if (!chars) {
			chars = ' ';
		}
		chars = String(chars);
		var ln = String(chars).length;
		if (ln === 0) {
			return str;
		}
		if (anySymbols) {
			var tmp = {};
			for (var i = 0; i < chars.length; i++) {
				tmp[chars[i]] = chars[i];
			}
			while(str.length > 0 && tmp[String(str).substring(0, 1)]) {
				str = str.substring(1, str.length);
			}
		} else {
			while(String(str).substring(0, ln) === chars) {
				str = str.substring(ln, str.length);
			}
		}

		return str;
	}
};